<template>
  <div class="modal" role="dialog">
    <div>
      <h4>Заблокировать пользователя {{ data }}?</h4>
    </div>

    <ValidationObserver ref="bannedUserForm">
      <ValidationProvider
        v-slot="{ errors }"
        class="md-size-100"
        mode="passive"
        tag="div"
      >
        <md-field :class="{ error: errors.length }">
          <ValidationError :errors="errors" />
          <label>На срок:</label>
          <md-select v-model="banned_data.time">
            <md-option
              v-for="time in timesOfBan"
              :key="`option_role_${time.id}`"
              :value="time.id"
            >
              {{ time.title }}
            </md-option>
          </md-select>
        </md-field>
      </ValidationProvider>

      <div class="buttons-wrap justify-end">
        <md-button
          class="md-raised md-success"
          type="submit"
          @click="blockUser()"
        >
          Подтвердить
        </md-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      banned_data: {
        user_id: 0,
        time: 1,
      },
      timesOfBan: [
        {
          id: 1,
          title: "1 день",
        },
        {
          id: 2,
          title: "1 неделю",
        },
        {
          id: 3,
          title: "1 месяц",
        },
        {
          id: 4,
          title: "1 год",
        },
        {
          id: 5,
          title: "неограниченно",
        },
      ],
    };
  },

  methods: {
    async blockUser() {
      try {
        this.$notify({
          message: "Пользователь заблокирован",
          icon: "task_alt",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "primary",
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  height: auto;
  padding: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px;
  background: #fff;
  min-width: 600px;
}
</style>
